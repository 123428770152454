<template>
  <div class="app-container">
    <el-form style="margin-left: 50px" label-width="120px">
      <el-form-item label="产品封面:">
        <img
          :src="JSON.parse(form.product_specs_combination_snap).product.cover"
          alt=""
          style="width: 160px; height: 160px"
        />
      </el-form-item>
      <el-form-item label="产品名称:">
        {{ JSON.parse(form.product_specs_combination_snap).product.name }}
      </el-form-item>
      <el-form-item label="产品规格:">
        {{ JSON.parse(form.product_specs_combination_snap).product_specs }}
      </el-form-item>
      <el-form-item label="产品数量:">
        {{ form.num }}
      </el-form-item>
      <el-form-item label="产品价格:">
        {{ form.pay_money }}
      </el-form-item>
      <el-form-item label="退货理由:">
        {{ form.product_child_order_refund.content }}
      </el-form-item>
      <el-form-item label="退货图片:">
        <img
          v-for="(item, index) in form.product_child_order_refund.images"
          :key="index"
          :src="item"
          alt=""
          style="width: 80px; height: 80px"
        />
      </el-form-item>
      <el-form-item label="驳回原因:">
        {{ form.product_child_order_refund.refuse_reason }}
      </el-form-item>
      <el-form-item>
        <el-button @click="$router.go(-1)">返回</el-button>
        <el-button
          type="danger"
          style="margin-left: 50px"
          :loading="btnLoading"
          @click="Refund"
        >
          驳回
        </el-button>
        <el-button
          type="success"
          style="margin-left: 50px"
          :loading="btnLoading"
          @click="pass"
        >
          通过
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      order: "",
      form: {},
      btnLoading: false,
    };
  },
  created() {
    this.order = this.$route.query.order;
    this.getDetail(this.$route.query.order);
  },
  mounted() {},
  methods: {
    pass() {
      this.btnLoading = true;
      this.$confirm("此操作将通过该用户的退货, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/productOrder/childOrderRefundPass",
            method: "post",
            params: {
              order_no: this.order,
            },
          })
            .then((response) => {
              this.$message({
                type: "success",
                message: "通过成功!",
              });
              // this.getDetail(this.order);
              this.$router.go(-1);
            })
            .catch(() => {
              this.btnLoading = false;
            });
        })
        .catch(() => {
          this.btnLoading = false;
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    Refund() {
      this.btnLoading = true;
      this.$prompt("请输入驳回理由", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          request({
            url: "/api/backend/productOrder/childOrderRefund",
            method: "post",
            params: {
              order_no: this.order,
              refuse_reason: value,
            },
          })
            .then((response) => {
              this.$message({
                type: "success",
                message: "驳回成功!",
              });
              // this.getDetail(this.order);
              this.$router.go(-1);
            })
            .catch(() => {
              this.btnLoading = false;
            });
        })
        .catch(() => {
          this.btnLoading = false;
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    getDetail(order_no) {
      request({
        url: "/api/backend/productOrder/childOrderDetail",
        method: "get",
        params: {
          order_no: order_no,
        },
      }).then((response) => {
        if (response.data) {
          this.form = response.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>